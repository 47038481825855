@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('/node_modules/react-grid-layout/css/styles.css');
@import url('/node_modules/react-resizable/css/styles.css');
.avatar-color {
	background-color: pink;
}

.main-container {
	width: 100vw;
	height: 100vh;
}

.text-danger {
	color: tomato;
}

.search-form-container {
	display: 'flex';
	width: '100%';
	gap: 20px;
	justify-content: space-between;
}
