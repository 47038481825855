.App {
	text-align: center;
}

body {
	width: 100vw;
	height: 100vh;
}

.date-picker-form {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 10px;
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		-webkit-print-color-adjust: exact;
	}
}

@media print {
	.page-break {
		margin-top: 0;
		display: block;
		page-break-before: auto;
	}
}

@page {
	size: auto;
	margin: 5mm 10mm;
}
